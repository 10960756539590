@import '../../../../styles/customMediaQueries.css';

.root {
  /* border-top: 1px solid var(--colorGrey100); */
  background: hsla(173, 36%, 27%, 1);
  background: linear-gradient(225deg, hsla(173, 36%, 27%, 1) 0%, hsla(207, 12%, 15%, 1) 94%);
  background: -moz-linear-gradient(225deg, hsla(173, 36%, 27%, 1) 0%, hsla(207, 12%, 15%, 1) 94%);
  background: -webkit-linear-gradient(225deg, hsla(173, 36%, 27%, 1) 0%, hsla(207, 12%, 15%, 1) 94%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#2D5F59", endColorstr="#21262A", GradientType=1);

  & > div {
    padding: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 38px 60px;
  max-width: 1600px;
  gap: 24px;

  @media (--viewport1280) {
    flex-direction: row;
    gap: 50px;
    padding: 68px 38px 112px;
  }
}

.section {
  
}

.sectionBottom {
  margin: 0 auto;
  padding: 12px 38px 16px;
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
    gap: 32px;
  }
  
  @media (--viewportLarge) {
    max-width: 860px;
  }
}

.sectionSeparator {
  border-bottom: 1px solid #859C96;
}

.sectionWithBtn {
  position: relative;
  
  @media (--viewport1280) {
    padding-bottom: 110px;
  }
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}


.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
  color: var(--colorWhite);
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.sloganDesktop {
  display: block;
}

.logo {
  /* grid-area: logo; */
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (--viewportLarge) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  max-width: 370px;
}

.logoImage {
  height: auto;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 298px);
  }
}

.columnTitle {
  color: var(--colorWhite);
  margin: 0 0 4px;
  padding: 0;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 22px;
  letter-spacing: -0.5px;
}

.ctaButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  margin: 24px 0 0;
  transition: all var(--transitionStyle);
  cursor: pointer;
  font-weight: var(--fontWeightLight);
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: var(--borderRadiusFull);
  background-color: var(--marketplaceGreen);
  color: var(--colorWhite);
  width: 100%;
  width: 282px;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Sora', sans-serif;

  @media (--viewport1280) {
    margin: 64px 0 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.ctaButton:focus,
.ctaButton:hover {
  outline: none;
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
  background-color: var(--marketplaceDarkGreen);
}

.links {
  color: var(--colorWhite);
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
  display: block;
  margin: 0;
  padding: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.infoTip {

  @media (--viewportMedium) {
    padding-left: 40px;
  }
}

.email {
  margin: 24px 0 16px;
  padding: 0;
}

.cards {
  display: flex;
  gap: 8px;
}

.cardsColumn {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.logo {

}

.secondSection {

  @media (--viewportMedium) {
    padding-left: 37px;
  }
}
